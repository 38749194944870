import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState, useCallback } from "react";

export default function App() {
  const [input, setInput] = useState("");
  const [message, setMessage] = useState("");
  const [busy, setBusy] = useState(false);
  const matches = Array.from(
    input.matchAll(
      /(\d{6})-[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g
    )
  );
  const m = matches.length === 1 ? matches[0] : null;
  const isValid = !!m;
  const submit = useCallback(() => {
    if (!m || busy) {
      return;
    }
    setBusy(true);
    setMessage("");
    fetch(`https://auxapi.amae-koromo.com/import/${m[0]}`, { method: "POST" })
      .catch((resp) => resp)
      .then(async (resp) => {
        try {
          const data = await resp.json();
          if (data.success) {
            setMessage("Success!");
            setInput("");
          } else {
            setMessage(
              "Error: " +
                (data.message || `HTTP status ${resp.status || "<unknown>"}`)
            );
          }
        } catch (e) {
          console.error(e);
          setMessage(`Error: HTTP status ${resp.status || "<unknown>"}`);
        }
      })
      .finally(() => {
        setBusy(false);
      });
  }, [m, busy]);
  return (
    <Container className="App" maxWidth="sm">
      <Box my={5}>
        <Typography variant="h1" sx={{ fontSize: "2rem" }}>
          MajSoul Stats Game Importer
        </Typography>
        <Box my={3}>
          <TextField
            id="outlined-basic"
            label="Game link"
            variant="outlined"
            fullWidth
            multiline
            disabled={busy}
            value={input}
            onChange={(e) => setInput(e.currentTarget.value)}
          />
        </Box>
        <LoadingButton
          onClick={submit}
          disabled={!isValid}
          loading={busy}
          variant="contained"
        >
          Submit
        </LoadingButton>
        <Box mt={3}>
          <Typography>{message}</Typography>
        </Box>
      </Box>
    </Container>
  );
}
